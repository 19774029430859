import PropTypes from 'prop-types';
import React, {useState} from 'react';

import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
} from '@material-ui/core';
import RenewIcon from '@material-ui/icons/Autorenew';
import mvdLogo from 'assets/mvd.svg';
import { FormattedMessage } from 'react-intl';
import transformUrl from 'transform-url';

import {
  FMS_START_PASSPORT_CHECK,
} from 'modules/fmsPassportCheck/graphql/mutations/fmsStartPassportCheck';

import { LastCheckTimeSubheader } from './LastCheckTimeSubheader';
import {
  GET_CONTACT_CHECK_PROCESSES,
} from '../graphql/queries/contactCheckProcesses';

const STATUS_COLORS = {
  active: 'rgb(34, 160, 83)',
  invalid: 'orange',
  inactive: 'rgb(221, 86, 86)', // rgb(224, 31, 25)
};

const FMS_CONTACT_PATH = `${process.env.REACT_APP_HOST}/contacts/:contactId/fms_passport_checks`;

FmsPassportCheckWidget.propTypes = {
  contactId: PropTypes.string,
  data: PropTypes.shape({
    checkedPassportNumber: PropTypes.string,
    createdAt: PropTypes.string,
    failedAt: PropTypes.string,
    finishedAt: PropTypes.string,
    startedAt: PropTypes.string,
    status: PropTypes.oneOf(['active', 'invalid', 'inactive']),
  }),
};

export default function FmsPassportCheckWidget({ contactId, data }) {
  const [startFmsPassportCheck, { loading }] = useMutation(
    FMS_START_PASSPORT_CHECK, {
    variables: { contactId },
    onError: (error) => {
      alert(`Произошла ошибка при запуске проверки ФМС ${error.message}`);
    },
    // TODO: сделать обновление кэша вместо запроса контакта
    refetchQueries: [{
      query: GET_CONTACT_CHECK_PROCESSES,
      variables: { contactId },
    }],
  });

  function handleClick() {
    startFmsPassportCheck();
  }

  const subheader = data ? (
    <LastCheckTimeSubheader
      {...{
        createdAt: data.createdAt,
        failedAt: data.failedAt,
        finishedAt: data.finishedAt,
        startedAt: data.startedAt,
      }}
    />
  ) : (
    <Typography color="textSecondary" variant="caption">
      <Box fontWeight="500">Паспорт не проверялся</Box>
    </Typography>
  );

  const contentBody = data ? (
    <Box height={64}>
      <Box color="rgba(0,0,0,.8)" fontSize={13} lineHeight="20px" mb={2}>
        <FormattedMessage
          id={`passportCheckStatus.passportNumber`}
          values={{
            number: (
              <Box component="span" fontWeight={500}>
                {data.checkedPassportNumber}
              </Box>
            ),
          }}
        />
      </Box>
      <Typography variant="h3">
        <Box color={STATUS_COLORS[data.status]}>
          <FormattedMessage id={`passportCheckStatus.${data.status}`} />
        </Box>
      </Typography>
      <Box color="rgba(0,0,0,.4)" fontSize={13} lineHeight="20px">
        <FormattedMessage id={`passportCheckStatus.${data.status}.subtitle`} />
      </Box>
    </Box>
  ) : (
    <Box display="flex" height={102} m="auto">
      <Button disabled={loading} fullWidth onClick={handleClick}>
        <FormattedMessage id="verification.checkButton" />
      </Button>
    </Box>
  );

  const ff = () => {

    // eslint-disable-next-line no-restricted-globals
    parent.postMessage('Серега тест', '*');
    return false;
  }

  return (
    <Card variant="outlined">

      <Box>
        <form id="form">

          <Button onClick={ff}>заебенить в родителя</Button>
        </form>
      </Box>



      <CardHeader
        action={
          data && (
            <IconButton
              disabled={loading}
              onClick={handleClick}
              sx={{ ml: 2 }}
            >
              <RenewIcon />
            </IconButton>
          )
        }
        avatar={<Box alt="Logo" component="img" height={40} src={mvdLogo} />}
        subheader={subheader}
        title={
          <Box fontSize={16} fontWeight={500}>
            <FormattedMessage id={`passportCheckStatus.title`} />
          </Box>
        }
      />
      <Box mt={-3} />
      <CardContent>{contentBody}</CardContent>
      {data ? (
        <CardActions>
          <Box px="3px">
            <Button
              href={transformUrl(FMS_CONTACT_PATH, { contactId })}
              size="small"
              target="_parent"
            >
              <FormattedMessage id="more" />
            </Button>
          </Box>
        </CardActions>
      ) : null}
    </Card>
  );
}
