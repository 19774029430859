import { gql } from '@apollo/client/core';

export const USERS_MINI_GROUPS = gql`
  query UserGroups {
    userGroups {
      administrator {
        user {
          id
        }
      }
      users {
        id
      }
    }
  }
`;